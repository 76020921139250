import auth from "./auth"
import material from "./material"
import company from "./company"
import car from "./car"
import course from "./course"
import subtype from "./subtype"
import user from "./user"
import group from "./group"
import report from "./report"
import type from "./type"
import setting from "./setting"



const resources = {
    auth,
    material,
    company,
    car,
    course,
    subtype,
    type,
    user,
    group,
    report,
    setting
};
export default resources;
