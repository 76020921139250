import React, {useEffect, useState, useRef} from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import {Box, Button, ButtonGroup} from '@chakra-ui/react'
import {
    FiEye,
    FiMoreVertical,
    FiPlusCircle
} from 'react-icons/fi';
import setting from "../../api/setting";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import {Stack, Skeleton} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel

} from '@chakra-ui/react'
import TrData from "../../components/TrData";

export default function Setting() {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);



    function onGet() {

        resources.setting.read(50, 1)
            .then((res) => {
                if (res) {
                    setData(res.data.data)
                    setLoader(false)
                }
            })
            .catch((er) => {
                console.log(er)
                setLoader(false)
            })


    }

    useEffect(() => {
        onGet()
    }, []);


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Settings</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>

                    <Box backgroundColor="#fff">

                        {
                            loader ? <Stack>
                                    <Skeleton height='40px'/>
                                    <Skeleton height='40px'/>
                                    <Skeleton height='40px'/>
                                </Stack> :
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th>Action</Th>
                                                <Th>Constant</Th>
                                                <Th isNumeric>Value</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                data.map((el, key) => {
                                                    return <TrData data={el} key={key} onRefresh={onGet}/>
                                                })
                                            }

                                        </Tbody>

                                    </Table>
                                </TableContainer>

                        }


                    </Box>
                </div>
            </div>

        </div>
    )
}
