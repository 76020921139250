import {
    Link
} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import { Button, Badge } from '@chakra-ui/react'
import {
    FiPlusCircle
} from 'react-icons/fi';

export default function index() {
    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Courses</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Courses</li>
                            </ol>
                        </nav>
                        <Link className="btn btn-app-default " to="/courses/create"><Button leftIcon={<FiPlusCircle />} colorScheme='brand'>New</Button></Link>
                    </div>

                    <DataTable column={[
                        { label: "ID", field: "id" },
                        { label: "Tonnage", field: "ton" },
                        { label: "Distance", field: "km" },
                        { label: "Time", field: "minute" },
                        { label: "Seller", field: "livreur", render: ['full_name'] },
                        { label: "Driver", field: "user", render: ['full_name'] },
                        {
                            label: "Status", field: "state", renderStyle: (el) => {
                                return <>
                                    {el.state == 1 && <Badge>pending</Badge>}
                                    {el.state == 2 && <Badge colorScheme='purple'>ongoing</Badge>}
                                    {el.state == 3 && <Badge colorScheme='green'>finished</Badge>}
                                </>
                            }
                        },
                        { label: "Car", field: "vehicule", render: ['matricule'] },
                    ]}

                        element={"courses"}
                        resource={resources.course}
                        filterOption={false}
                        filterLoader={() => { }}
                        action={(data) => {

                        }}
                        deleteItem
                        view
                    />
                </div>
            </div>

        </div>
    )
}
