import Axios from "../Axios";

const course = {
    read(limit: number, page: number, rule: null) {
        return Axios.get('/courses?limit=' + limit + "&page=" + page + rule, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/courses/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object, token: string) {
        return Axios.post('/courses', data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/courses/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/courses/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default course;
