import React, { useState, useEffect } from 'react'
import Select from "react-select";
import {
    FiTrash2
} from 'react-icons/fi';

function InvoiceItem(props) {
    const { position, onDelete, data, onChange, article } = props

    const [item, setItem] = useState(data.product_id);
    const [quantity, setQuantity] = useState(data.quantity);

    useEffect(() => {
        onChange(position, {
            product_id: item,
            quantity: quantity,
        });
    }, [quantity, item]);

    function onSelectProduct(e) {
        setItem(e.value.toString())
        setQuantity(quantity);
    }

    function onChangeQty(e) {
        setQuantity(e);
    }



    return (<div className="card card-body mt-2 ">
        <div className="row ">
            <div className="col">
                <Select isClearable onChange={(e) => onSelectProduct(e)} options={article} />
            </div>
            <div className="col">
                <input type="text"
                    className="form-control"
                    onChange={(e) => onChangeQty(e.target.value)}
                    value={quantity} />
            </div>

            <div className="col-lg-1 align-content-center align-items-center justify-content-center">
                {
                    !position == 0 && <FiTrash2 onClick={() => onDelete(position)} />
                }
            </div>
        </div>
    </div>)
}

export default InvoiceItem
