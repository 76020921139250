import React, { useState, useEffect } from 'react'
import {
    Link
} from "react-router-dom";
import Select from 'react-select'
import resources from '../../api';
import { Button, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

export default function Create() {
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [drivers, setDrivers] = useState([])
    const [data, setData] = useState()
    const toast = useToast()
    const navigate = useNavigate();


    async function onGet() {
        let com = await resources.company.read(10000)
        if (com.status === 200) {
            let a = []
            com.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

        let bin = await resources.user.read(10000, 1, "&roles=driver")
        if (bin.status === 200) {
            let a = []
            bin.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.full_name,
                })
            })
            setDrivers(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [])

    function create(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        resources.car.create(object, localStorage.getItem("userToken"))
            .then((res)=>{
                if (res.status == 200) {
                    setLoader(false)
                    toast({
                        title: 'Car created.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/cars");
                }
            }).catch((error)=>{

                console.log(error.response.data.errors)
                setLoader(false)
                if (error.response.data.errors)
                    error.response.data.errors.forEach(el=>{
                        toast({
                            title: el.field +": " + el.message,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                    })


            })



    }
    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Car</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/cars">Car</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">new car</li>
                            </ol>
                        </nav>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card'>

                                <div className='card-body'>
                                    <form onSubmit={create}>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Immatriculation <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="matricule" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Capacite</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                name="capacite" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Companies</label>
                                            <Select name="society_id" options={companies} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Driver</label>
                                            <Select name="user_id" options={drivers} />
                                        </div>

                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
