import React, {useState, useEffect, useRef} from 'react'
import Header from '../../components/Header'
import {
    Link, useParams
} from "react-router-dom";
import Icon from '../../ui/Icon';
import {useReactToPrint} from 'react-to-print';
import resources from '../../api';
import config from '../../constants';
// import JsPDF from 'jspdf';
import {ComponentToPrint} from '../../components/ComponentToPrint';
import Material from '../../components/Material';
import {
    Button, ButtonGroup, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiFilter,
    FiChevronDown,
    FiCode
} from 'react-icons/fi';

import {
    ImFileExcel,
    ImFilePdf
} from 'react-icons/im';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'

export default function View() {
    const [data, setData] = useState({})
    const [code, setCode] = useState([])
    const {id} = useParams()


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    async function onGet() {

        let mat = await resources.course.readOne(id)
        if (mat.status === 200) {

            setData(mat.data)
            setCode(mat.data.items)

        }


    }

    useEffect(() => {
        onGet();
    }, [])


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Courses</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/courses">Courses</Link></li>

                            </ol>
                        </nav>

                        <ButtonGroup gap='2'>
                            <Button leftIcon={<ImFileExcel/>} onClick={handlePrint} colorScheme='brand'> Save as
                                pdf</Button>
                        </ButtonGroup>

                    </div>

                    <div className='row justify-content-center mt-5'>
                        <div className='col-lg-9 mb-5'>

                            <ComponentToPrint ref={componentRef}>
                                {
                                    data && <div className='card'>


                                        <ul class="list-group list-group list-group-flush">
                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">ID</div>
                                                    {data?.id}
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">Seller</div>
                                                    {data?.livreur?.full_name}
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">Driver</div>
                                                    {data?.user?.full_name}
                                                </div>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">Car imatriculation</div>
                                                    {data?.vehicule?.matricule}
                                                </div>
                                            </li>


                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">Items loaded</div>
                                                </div>
                                            </li>

                                            <TableContainer>
                                                <Table variant='simple'>
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Product</Th>
                                                            <Th isNumeric>Tonnage</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            data && code.map((el, key) => {
                                                                return <Tr key={key}>
                                                                    <Td>{el?.product?.name}</Td>
                                                                    <Td isNumeric>{el?.quantity}</Td>
                                                                </Tr>
                                                            })
                                                        }

                                                    </Tbody>

                                                    <Tfoot>
                                                        <Tr>
                                                            <Th>Total loaded</Th>
                                                            <Th isNumeric>{data.ton}</Th>
                                                        </Tr>
                                                    </Tfoot>

                                                </Table>
                                            </TableContainer>

                                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Items pricing</div>
                                                </div>
                                            </li>

                                            <TableContainer>
                                                <Table variant='simple'>
                                                    <Tr>
                                                        <Th>Constant</Th>
                                                        <Th isNumeric>value</Th>
                                                        <Th isNumeric>multiply by</Th>
                                                        <Th isNumeric>Total</Th>
                                                    </Tr>

                                                    <Tbody>
                                                        <Tr>
                                                            <Td>Base Fare</Td>
                                                            <Td isNumeric>-</Td>
                                                            <Td isNumeric>-</Td>
                                                            <Td isNumeric>{data.base}$</Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td>Per Km</Td>
                                                            <Td isNumeric>{data.km}</Td>
                                                            <Td isNumeric>{data.per_km}</Td>
                                                            <Td isNumeric>{data.per_km * data.km}$</Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td>Per Min</Td>
                                                            <Td isNumeric>{data.minute}</Td>
                                                            <Td isNumeric>{data.per_min}</Td>
                                                            <Td isNumeric>{data.per_min * data.minute}$</Td>
                                                        </Tr>

                                                        <Tr>
                                                            <Td>Per Ton</Td>
                                                            <Td isNumeric>{data.ton}</Td>
                                                            <Td isNumeric>{data.per_ton}</Td>
                                                            <Td isNumeric>{data.ton * data.per_ton}$</Td>
                                                        </Tr>

                                                    </Tbody>
                                                    <Tfoot>
                                                        <Tr>
                                                            <Th>Total</Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                            <Th isNumeric>{data.base + (data.per_km * data.km) + (data.per_min * data.minute) + (data.ton * data.per_ton)}$</Th>
                                                        </Tr>
                                                    </Tfoot>
                                                </Table>
                                            </TableContainer>


                                        </ul>


                                    </div>
                                }

                            </ComponentToPrint>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
