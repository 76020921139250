import * as React from "react"
import {
  ChakraProvider,
  extendTheme,

} from "@chakra-ui/react"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import SidebarWithHeader from "./components/SidebarWithHeader";
import LoginPage from "./pages/Login/LoginPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Material from "./pages/Material"
import MaterialCreate from "./pages/Material/Create"
import MaterialEdit from "./pages/Material/Edit"
import MaterialView from "./pages/Material/View"

import Company from "./pages/Company"
import CompanyCreate from "./pages/Company/Create"
import CompanyEdit from "./pages/Company/Edit"

import Bin from "./pages/Car"
import BinCreate from "./pages/Car/Create"
import BinEdit from "./pages/Car/Edit"

import Storage from "./pages/Course"
import StorageCreate from "./pages/Course/Create"
import StorageEdit from "./pages/Course/Edit"
import CourseView from "./pages/Course/View"

import Subtype from "./pages/Subtype"
import SubtypeCreate from "./pages/Subtype/Create"
import SubtypeEdit from "./pages/Subtype/Edit"

import Type from "./pages/Type"
import TypeCreate from "./pages/Type/Create"
import TypeEdit from "./pages/Type/Edit"

import User from "./pages/User"
import UserCreate from "./pages/User/Create"
import UserEdit from "./pages/User/Edit"

import Report from "./pages/Report"
import SingleView from "./pages/Material/SingleView";

import Setting from "./pages/Setting/Setting"

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
    500: "#186D3C"
  },
}

const theme = extendTheme({ colors })

export const App = () => (
  <ChakraProvider theme={theme}>

    <Router>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/materials' element={<SidebarWithHeader children={<Material />} />} />
        <Route path='/material/create' element={<SidebarWithHeader children={<MaterialCreate />} />} />
        <Route path='/material/edit/:id' element={<SidebarWithHeader children={<MaterialEdit />} />} />
        <Route path='/material/view/:id' element={<SidebarWithHeader children={<MaterialView />} />} />
        <Route path='/material/single/:id' element={<SingleView />} />

        <Route path='/companies' element={<SidebarWithHeader children={<Company />} />} />
        <Route path='/company/create' element={<SidebarWithHeader children={<CompanyCreate />} />} />
        <Route path='/company/edit/:id' element={<SidebarWithHeader children={<CompanyEdit />} />} />


        <Route path='/users' element={<SidebarWithHeader children={<User />} />} />
        <Route path='/user/create' element={<SidebarWithHeader children={<UserCreate />} />} />
        <Route path='/user/edit/:id' element={<SidebarWithHeader children={<UserEdit />} />} />

        <Route path='/cars' element={<SidebarWithHeader children={<Bin />} />} />
        <Route path='/cars/create' element={<SidebarWithHeader children={<BinCreate />} />} />
        <Route path='/cars/edit/:id' element={<SidebarWithHeader children={<BinEdit />} />} />

        <Route path='/courses' element={<SidebarWithHeader children={<Storage />} />} />
        <Route path='/courses/create' element={<SidebarWithHeader children={<StorageCreate />} />} />
        <Route path='/courses/edit/:id' element={<SidebarWithHeader children={<StorageEdit />} />} />
        <Route path='/courses/view/:id' element={<SidebarWithHeader children={<CourseView />} />} />

        <Route path='/subtypes' element={<SidebarWithHeader children={<Subtype />} />} />
        <Route path='/subtype/create' element={<SidebarWithHeader children={<SubtypeCreate />} />} />
        <Route path='/subtype/edit/:id' element={<SidebarWithHeader children={<SubtypeEdit />} />} />

        <Route path='/types' element={<SidebarWithHeader children={<Type />} />} />
        <Route path='/type/create' element={<SidebarWithHeader children={<TypeCreate />} />} />
        <Route path='/type/edit/:id' element={<SidebarWithHeader children={<TypeEdit />} />} />

        <Route path='/settings' element={<SidebarWithHeader children={<Setting />} />} />

        <Route path='/report' element={<SidebarWithHeader children={<Report />} />} />

      </Routes>
    </Router>


  </ChakraProvider>
)
