import Axios from "../Axios";

const material = {

    readOne(id: string) {
        return Axios.get('/products/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    read(limit: number, page: number, rule: null) {
        return Axios.get('/products?limit=' + limit + "&page=" + page, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object) {
        return Axios.post('/products', data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    addImage(data: object, id: string) {
        return Axios.put('/products/' + id + "/img", data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },

    removeImage(id: string, el: string) {
        return Axios.delete('/products/' + id + "/remove/" + el, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    put(data: object, id: string) {
        return Axios.put('/products/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/products/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
}

export default material;
