import React, { useState, useEffect } from 'react'
import {
    Link, useParams
} from "react-router-dom";
import resources from '../../api';
import Select from 'react-select'
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

export default function Edit() {
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [groups, setGroups] = useState([])
    const [roles, setRole] = useState()
    const toast = useToast()
    const [data, setData] = useState({})
    const navigate = useNavigate();
    const { id } = useParams();

    const role = [
        { label: "Driver", value: "driver" },
        { label: "Seller", value: "vendor" },
        { label: "Admin", value: "admin" },
        { label: "Company", value: "society" },
    ]
    async function onGet() {

        let res = await resources.user.readOne(id)
        if (res) {
            setData(res.data)
        }

        let cus = await resources.company.read(10000)
        if (cus.status === 200) {
            let a = []
            cus.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [])

    function create(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        formData.append("password_confirmation", e.target.password.value)
        formData.append("roles", data.roles)

        let object = {};
        formData.forEach((value, key) => object[key] = value);

        resources.user.update(id, object)

            .then((res)=>{
                if (res.status == 200) {
                    setLoader(false)
                    toast({
                        title: 'User updated.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/users");
                }
            }).catch((error)=>{

                console.log(error.response.data.errors)
                setLoader(false)
                if (error.response.data.errors)
                    error.response.data.errors.forEach(el=>{
                        toast({
                            title: el.field +": " + el.message,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                    })


            })



    }
    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>User</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/users">Users</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">update user</li>
                            </ol>
                        </nav>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card'>

                                <div className='card-body'>
                                    <form onSubmit={create}>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Full name <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="full_name" defaultValue={data?.full_name} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Username <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="username" defaultValue={data?.username} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Password </label>
                                            <input type="password" className="form-control" id="exampleInputEmail1"
                                                name="password" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Role</label>
                                            <Select name="roles" options={role} onChange={(e) => setRole(e.value)} />
                                        </div>



                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Companies </label>
                                            <Select name='society_id' options={companies} />
                                        </div>


                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
