import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import {
    Link
} from "react-router-dom";
import resources from '../../api';
import { Button, useToast } from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import InvoiceItem from "../../components/InvoiceItem";

export default function Create() {
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();

    const [total, setTotal] = useState(0),
        [reduct, setReduct] = useState(0),
        [items, setItems] = useState([{
            product_id: '',
            quantity: 0,
        }]),
        [article, setArticle] = useState([]),
        [drivers, setDrivers] = useState([]),
        [driver, setDriver] = useState(),
        [sellers, setSellers] = useState([]),
        [seller, setSeller] = useState(),
        [cars, setCars] = useState([]),
        [car, setCar] = useState(),
        [local, setLocal] = useState()

    async function onGet() {
        let pro = await resources.material.read()
        if (pro.status === 200) {
            let a = []
            pro.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setArticle(a)
        }

        let sell = await resources.user.read(100000, 1, "&roles=vendor")
        if (sell.status === 200) {
            let a = []
            sell.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.full_name,
                })
            })
            setSellers(a)
        }

        let cus = await resources.user.read(100000, 1, "&roles=driver")
        if (cus.status === 200) {
            let a = []
            cus.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.full_name,
                })
            })
            setDrivers(a)
        }


        let car = await resources.car.read(100000)
        if (car.status === 200) {
            let a = []
            car.data.data.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.matricule,
                })
            })
            setCars(a)
        }
    }

    useEffect(() => {
        onGet();

    }, [])



    function onAddItem() {
        setItems(e => [...e, {
            product_id: '',
            quantity: 0,
        }])
    }

    function onDelete(e) {
        let a = []
        items.forEach((el, key) => {
            if (key != e) {
                a.push(el)
            }
        })
        setItems(a)

        let b = 0
        items.forEach(el => {
            if (el.quantity) {
                b += parseInt(el.quantity)
            }

        })
        setTotal(b)
    }

    function handle(id, e) {
        const a = items;
        a.forEach((el, key) => {
            if (id == key) {
                el.product_id = e.product_id;
                el.quantity = e.quantity;

            }
        });
        setItems(a);

        let b = 0
        items.forEach(el => {
            if (el.quantity) {

                b += parseInt(el.quantity)

            }

        })

        setTotal(b)

    }

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            livreur_id: seller.toString(),
            items: items,
            vehicule_id: car.toString(),
            user_id: driver.toString(),
            ton: total
        };
        resources.course.create(object, localStorage.getItem("userToken"))

            .then((res)=>{
                if (res.status == 200) {
                    setLoader(false)
                    toast({
                        title: 'Course created.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/courses");
                }
            }).catch((error)=>{

            setLoader(false)
            if (error.response.data.errors)
                error.response.data.errors.forEach(el=>{
                    toast({
                        title: el.field +": " + el.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                })


        })



    }
    return (
        <div>
            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Courses</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/courses">Courses</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">new courses</li>
                            </ol>
                        </nav>

                        <Button isLoading={loader} leftIcon={<FiSave />} colorScheme='brand' onClick={onCreate}>Save</Button>
                    </div>
                    <div className="row  mt-3 mb-3">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Seller <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <Select onChange={(e) => setSeller(e.value)} options={sellers} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Driver <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <Select onChange={(e) => setDriver(e.value)} options={drivers} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Car <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <Select onChange={(e) => setCar(e.value)} options={cars} />
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Tonnage
                                                    <span style={{ color: "red" }}>*</span></label>
                                                <input type="text" readOnly value={total} className="form-control" id="exampleInputEmail1"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="card card-body">
                                    <div className="row">
                                        <div className="col-lg-5"><h6>Product</h6></div>
                                        <div className="col-lg-2"><h6>Quantity</h6></div>
                                    </div>
                                </div>

                                {
                                    items.map((el, key) => {
                                        return <InvoiceItem key={key} position={key} data={el} onDelete={onDelete}
                                            onChange={handle} article={article} />
                                    })
                                }

                                <Button type="button" onClick={onAddItem} leftIcon={<FiPlusCircle />} colorScheme='brand' className="w-100 mt-3">Add line</Button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
