import React, { useState, useEffect } from 'react'
import {
    Link
} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import Select from 'react-select'
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiFilter
} from 'react-icons/fi';


export default function Material() {

    const [filter, setFilter] = useState(false)
    const [filterOption, setFilterOption] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])




    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('name')) {
            el = "&name=" + formData.get('name')
        }
        if (formData.get('unique_number')) {
            el += "&unique_number=" + formData.get('unique_number')
        }

        if (formData.get('type')) {
            el += "&type=" + formData.get('type')
        }
        if (formData.get('stock_on_hand')) {
            el += "&stock_on_hand=" + formData.get('stock_on_hand')
        }

        window.location.replace("/materials?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Products</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Products</li>
                            </ol>
                        </nav>
                        <div className="d-flex ">

                            <ButtonGroup gap='2'>
                                {/*{*/}
                                {/*    filterOption ? <Button leftIcon={<FiFilter />} colorScheme='red' onClick={() => setFilterOption((e) => !e)} >Close filter</Button>*/}
                                {/*        : <Button leftIcon={<FiFilter />} colorScheme='green' onClick={() => setFilterOption((e) => !e)} > Open filter</Button>*/}
                                {/*}*/}

                                <Link to="/material/create"><Button leftIcon={<FiPlusCircle />} colorScheme='brand'>New</Button></Link>
                            </ButtonGroup>
                        </div>

                    </div>

                    <div className="card card-body mb-3 mt-4">
                        <form onSubmit={filerArticle}>
                            <div className="row">

                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" name="name" />
                                </div>


                                {
                                    localStorage.getItem("userRole") === "CLIENT" && <input type="hidden" class="form-control" id="exampleFormControlInput1" name="company" defaultValue={localStorage.getItem("userCompany")} />
                                }

                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Stock on hand" name="stock_on_hand" />
                                </div>



                                <div className="col">
                                    <Button isLoading={filter} className="w-100" colorScheme='brand' type="submit"> Apply</Button>
                                </div>

                            </div>

                        </form>

                    </div>

                    <DataTable column={[

                        { label: "Name", field: "name" },
                    ]}

                        filterOption={filterOption}
                        filterLoader={onChangeFilterLoader}
                        element={"material"}
                        resource={resources.material}
                        action={(data) => {

                        }}
                        update
                        deleteItem

                    />
                </div>
            </div>

        </div>
    )
}
