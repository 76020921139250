import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Icon from '../../ui/Icon';
import { Link, useParams } from "react-router-dom";
import resources from '../../api';
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

export default function Edit() {

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})
    const { id } = useParams();
    const toast = useToast()
    const navigate = useNavigate();


    async function read() {
        let res = await resources.company.readOne(id)
        if (res) {
            setData(res.data)
        }
    }
    async function update(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
       resources.company.update(id, object, localStorage.getItem("userToken"))
            .then((res)=>{
                if (res.status == 200) {
                    setLoader(false)
                    toast({
                        title: 'Company update.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/companies");
                }
            }).catch((error)=>{

                console.log(error.response.data.errors)
                setLoader(false)
                if (error.response.data.errors)
                    error.response.data.errors.forEach(el=>{
                        toast({
                            title: el.field +": " + el.message,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                    })


            })
    }

    useEffect(() => {
        read()
    }, [])


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Materials</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/companies">Materials</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">update material</li>
                            </ol>
                        </nav>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card'>

                                <div className='card-body'>
                                    <form onSubmit={update} >


                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Name<span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="name" defaultValue={data?.name} />
                                        </div>


                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Contact</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                name="contact" defaultValue={data?.contact} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Adresse </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                name="adress" defaultValue={data?.adress} />
                                        </div>
                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
