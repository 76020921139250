import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import resources from '../../api';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";


export default function Create() {
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const toast = useToast()
    const navigate = useNavigate();


    function create(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        resources.material.create(object, localStorage.getItem("userToken"))
            .then((res)=>{
                if (res.status == 200) {
                    setLoader(false)
                    toast({
                        title: 'Product created.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/materials");
                }
            }).catch((error)=>{

            console.log(error.response.data.errors)
            setLoader(false)
            if (error.response.data.errors)
                error.response.data.errors.forEach(el=>{
                    toast({
                        title: el.field +": " + el.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                })


        })


    }

    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Product</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/materials">Products</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">new material</li>
                            </ol>
                        </nav>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card'>

                                <div className='card-body'>
                                    <form onSubmit={create}>


                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Name <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="name" />
                                        </div>



                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
