import React from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
    FiPlusCircle
} from 'react-icons/fi';

export default function index() {
    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Users</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Users</li>
                            </ol>
                        </nav>
                        <Link className="btn btn-app-default " to="/user/create"><Button leftIcon={<FiPlusCircle />} colorScheme='brand'>New</Button></Link>
                    </div>

                    <DataTable column={[

                        { label: "Full name", field: "full_name" },
                        { label: "User name", field: "username" },
                        { label: "Role", field: "roles" }
                    ]}
                        filterOption={false}
                        filterLoader={() => { }}

                        element={"user"}
                        resource={resources.user}
                        action={(data) => {

                        }}
                        update
                        deleteItem

                    />
                </div>
            </div>

        </div>
    )
}
